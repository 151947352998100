import JSONTable, { makeEpoch } from 'components/common/JSONTable'
import { JSONObject, OvenData } from 'utils/oatsApi'

type Props = {
  oven?: OvenData
  includePageLink?: boolean
  error?: string
  loading?: boolean
}

const OvenDataViewer = ({ oven, includePageLink, error, loading }: Props) => {
  let data: JSONObject = oven && !error && !loading ? (oven as JSONObject) : {}

  if (includePageLink && oven) {
    data = {
      ...data,
      pageLink: `${window.location.origin}/ovens/${oven.thingName}`,
    }
  }

  if (error) {
    data = {
      error,
    }
  }

  if (loading) {
    data = {
      loading,
    }
  }

  if (oven && oven.connectivity) {
    if (oven.connectivity.connected) {
      data = {
        connected: oven.connectivity.timestamp,
        ...data,
      }
    } else {
      data = {
        disconnected:
          makeEpoch(oven.connectivity.timestamp.toString()) +
          ' ' +
          oven.connectivity.disconnectReason,
        ...data,
      }
    }

    delete data.connectivity
  }

  return (
    <div className="rounded-lg overflow-clip bg-slate-50 shadow-lg">
      <JSONTable data={data} />
    </div>
  )
}

export default OvenDataViewer
