import { Tooltip, Checkbox } from '@tovala/component-library'
import {
  CircleFilledIcon,
  CloudOutlineIcon,
  HardwareChipOutlineIcon,
} from 'components/common/Icons'
import PaginationTableFooter from 'components/common/PaginationTableFooter'
import Table from 'components/common/Table'
import { useState } from 'react'
import { fetchFavorites, OvenData } from 'utils/oatsApi'
import EditThingsModal from './EditThingsModal'
import { useQuery } from '@tanstack/react-query'

const OvenLookupResult = ({
  page,
  limit,
  setPage,
  setLimit,
  isLoading,
  isError,
  data,
  error,
  isPreviousData,
  invalidate,
}: {
  page: number
  limit: number
  setPage: (page: ((page: number) => number) | number) => void
  setLimit: (limit: ((limit: number) => number) | number) => void
  isLoading: boolean
  isError: boolean
  data: { ovens: OvenData[]; total: number } | undefined
  error: unknown
  isPreviousData: boolean
  invalidate: () => void
}) => {
  const [selected, setSelected] = useState<OvenData[]>([])
  const [editOpen, setEditOpen] = useState<boolean>(false)

  const { data: allGlobalFavorites } = useQuery({
    queryKey: ['all-global-favorites'],
    queryFn: async () => {
      return fetchFavorites(1, 1000, true)
    },
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60,
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    retry: 0,
  })

  return (
    <div
      className={
        'relative overflow-x-hidden rounded-lg shadow-lg mb-4 max-w-[80vw] xl:max-h-screen sm:max-w-96'
      }
    >
      <div className="flex gap-2 ml-2 cursor-pointer select-none">
        <div>
          {selected.length > 0 ? (
            <div
              className="underline"
              onClick={() => {
                setSelected([])
              }}
            >
              Unselect All
            </div>
          ) : (
            <div
              className="underline"
              onClick={() => {
                setSelected(data?.ovens ?? [])
              }}
            >
              Select All
            </div>
          )}
        </div>
        {selected.length > 0 && (
          <div
            className="underline"
            onClick={() => {
              setEditOpen(true)
            }}
          >
            Edit {selected.length} selected
          </div>
        )}
      </div>
      <Table
        columns={[
          {
            key: 'select',
            title: <></>,
            width: 'w-6',
          },
          { key: 'thingName', title: 'Name', width: 'w-40' },
          { key: 'serialNumber', title: 'Serial No.', width: 'w-36' },
          { key: 'deviceModel', title: 'Model', width: 'w-20' },
          {
            key: 'firmwareImage',
            title: 'Firmware',
            width: 'w-12',
            tooltip: true,
          },
          { key: 'ovenState', title: 'State', width: 'w-20', tooltip: true },
          { key: 'connectivity', title: 'Status', width: 'w-6' },
          { key: 'actions', title: 'Links', width: 'w-14' },
        ]}
        data={
          data?.ovens?.map((oven) => ({
            select: (
              <Checkbox
                checked={selected.includes(oven)}
                name={oven.thingName}
                onChange={() => {
                  setSelected((prev) =>
                    prev.includes(oven)
                      ? prev.filter((item) => item !== oven)
                      : [...prev, oven],
                  )
                }}
              />
            ),
            thingName: (
              <a className="text-blue-400" href={'/ovens/' + oven.thingName}>
                {allGlobalFavorites?.find(
                  (favorite) => favorite.deviceID === oven.thingName,
                )?.name || oven.thingName}
              </a>
            ),
            serialNumber: oven.attributes.serialNumber,
            deviceModel: oven.attributes?.deviceModel,
            firmwareImage: oven.shadow?.reported?.firmwareVersion as string,
            ovenState: oven.shadow?.reported?.ovenState as string,
            connectivity: oven.connectivity.connected ? (
              <span aria-label="Connected" className="text-green">
                <Tooltip
                  trigger={
                    <div>
                      <CircleFilledIcon />
                    </div>
                  }
                >
                  {'Connected' +
                    (oven.connectivity?.timestamp
                      ? ' - ' +
                        new Date(oven.connectivity.timestamp).toLocaleString()
                      : '')}
                </Tooltip>
              </span>
            ) : (
              <span aria-label="Disconnected" className="text-red">
                <Tooltip
                  trigger={
                    <div>
                      <CircleFilledIcon />
                    </div>
                  }
                >
                  {'Disconnected' +
                    (oven.connectivity?.disconnectReason
                      ? ' - ' + oven.connectivity.disconnectReason
                      : '') +
                    (oven.connectivity?.timestamp
                      ? ' - ' +
                        new Date(oven.connectivity.timestamp).toLocaleString()
                      : '')}
                </Tooltip>
              </span>
            ),
            actions: (
              <div className="flex gap-4">
                <a
                  className="text-blue-400 w-6"
                  href={oven.aws_thing_url}
                  rel="noreferrer"
                  target="_blank"
                >
                  <HardwareChipOutlineIcon />
                </a>
                <a
                  className="text-blue-400 w-6"
                  href={oven.aws_cloudwatch_url}
                  rel="noreferrer"
                  target="_blank"
                >
                  <CloudOutlineIcon />
                </a>
              </div>
            ),
          })) || []
        }
      >
        <PaginationTableFooter
          current={data?.ovens?.length ?? 0}
          isLoading={isPreviousData}
          onChangePage={setPage}
          onChangeRowsPerPage={setLimit}
          page={page}
          respectTotal={true}
          rowsPerPage={limit}
          total={data?.total ?? 0}
        >
          {isLoading && (
            <div className="text-center text-gray-500 p-4 flex-1">
              Loading...
            </div>
          )}

          {isError && (
            <div className="text-center text-red-500 p-4 flex-1">
              Error: {(error as Error)?.message ?? error}
            </div>
          )}

          {data?.ovens?.length === 0 && (
            <div className="text-center text-gray-500 p-4 flex-1">
              No results found
            </div>
          )}
        </PaginationTableFooter>
      </Table>
      {selected.length > 0 && editOpen && (
        <EditThingsModal
          invalidate={() => {
            setSelected([])
            invalidate()
          }}
          selected={selected}
          setEditOpen={setEditOpen}
        />
      )}
    </div>
  )
}

export default OvenLookupResult
