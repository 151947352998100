import { useParams } from 'react-router-dom'
import {
  addFavorite,
  fetchFavorite,
  fetchThing,
  tryGetDevToken,
} from 'utils/oatsApi'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import OvenDataViewer from './OvenDataViewer'
import OvenEventViewer from './OvenEventViewer'
import OvenCommandsExecutor from './OvenCommands'
import OvenLogs from './OvenLogs'
import OvenEvents from './OvenEvents'
import ThingJobHistory from './OvenJobsViewer'
import Dropdown from 'components/common/Dropdown'
import { DropdownMenu, MenuItem } from 'components/common/DropdownMenu'
import {
  Checkbox,
  Input,
  MenuIcon,
  Modal,
  ModalHeader,
  StarIcon,
  Tooltip,
} from '@tovala/component-library'
import { clsx } from 'clsx'
import { useState } from 'react'
import EditThingsModal from './EditThingsModal'
import ImagePublish from './ImagePublish'

const PreOvenPage = () => {
  const { ovenId } = useParams()

  if (!ovenId) {
    return <></>
  }

  return <OvenPage ovenId={ovenId} />
}

const OvenPage = ({ ovenId }: { ovenId: string }) => {
  const queryClient = useQueryClient()

  const [favoritesOpen, setFavoritesOpen] = useState(false)

  const [editOpen, setEditOpen] = useState(false)

  const [publishOpen, setPublishOpen] = useState(false)

  const { data, isLoading, isError } = useQuery({
    queryKey: ['oven', ovenId],
    queryFn: async () => {
      const bearerToken = tryGetDevToken()
      return await fetchThing(bearerToken, ovenId)
    },
    keepPreviousData: true,
    cacheTime: 1000 * 60,
    staleTime: 1000 * 60,
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 60,
    retry: 0,
  })

  const { data: favoritesData } = useQuery({
    queryKey: ['favorites', ovenId],
    queryFn: async () => {
      return await fetchFavorite(ovenId)
    },
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60,
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    retry: 0,
  })

  const myFavorite = favoritesData?.find((f) => f.user !== '0')
  const universalFavorite = favoritesData?.find((f) => f.user === '0')

  const [myFavoriteFavorited, setMyFavoriteFavorited] = useState(
    myFavorite?.favorited,
  )
  const [universalFavoriteName, setUniversalFavoriteName] = useState(
    universalFavorite?.name,
  )

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="flex text-k/44_110 font-medium md:text-k/36_110 flex-1 gap-2">
          <span>Oven</span>
          {universalFavorite &&
          universalFavorite?.name !== universalFavorite?.deviceID ? (
            <div className="flex w-max items-center gap-2">
              <div
                className={clsx({
                  'text-orange-1': universalFavorite.favorited,
                })}
              >
                {universalFavorite.name}
              </div>
              <div className="text-k/20_110"> - {ovenId}</div>
            </div>
          ) : (
            ovenId
          )}
          {myFavorite && myFavorite.favorited && (
            <div className="flex w-max items-center gap-2 text-k/20_110">
              <Tooltip
                trigger={
                  <div className="w-4 text-orange-1">
                    <StarIcon />
                  </div>
                }
              >
                My Favorite
              </Tooltip>
            </div>
          )}
        </h1>
        <div>
          <Dropdown
            trigger={
              <button className="btn btn-primary w-6 h-6">
                <MenuIcon />
              </button>
            }
          >
            <DropdownMenu>
              <MenuItem
                key={'favorite'}
                label={'Favorite'}
                onClick={() => {
                  setMyFavoriteFavorited(myFavorite?.favorited)
                  setUniversalFavoriteName(universalFavorite?.name)
                  setFavoritesOpen(true)
                }}
              />
              <MenuItem
                key={'edit'}
                label={'Edit'}
                onClick={() => {
                  setEditOpen(true)
                }}
              />
              <MenuItem
                key={'publish'}
                label={'OTA Publish'}
                onClick={() => {
                  setPublishOpen(true)
                }}
              />
            </DropdownMenu>
          </Dropdown>
          {favoritesOpen && (
            <Modal onCloseModal={() => setFavoritesOpen(false)}>
              <ModalHeader onClickClose={() => setFavoritesOpen(false)}>
                <h1 className="text-xl font-bold">Favorite Device</h1>
              </ModalHeader>
              <div className="p-2">
                <div className="min-w-64 m-2">
                  {/* name */}
                  <div>
                    <div className="text-lg font-bold">Name</div>
                    <Input
                      onChange={(e) => {
                        setUniversalFavoriteName(e.target.value)
                      }}
                      value={universalFavoriteName}
                    />
                  </div>
                  {/* favorited */}
                  <div>
                    <div className="text-lg font-bold">My Favorite</div>
                    <div className="flex gap-2">
                      <Checkbox
                        checked={myFavoriteFavorited}
                        name={'my-favorite'}
                        onChange={(e) => {
                          setMyFavoriteFavorited(e.target.checked)
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <button
                      className="bg-orange-1 text-white rounded-lg p-2 w-20 h-10 flex justify-center items-center"
                      onClick={async () => {
                        setFavoritesOpen(false)
                        // Update both favorites
                        const myName = universalFavoriteName || ovenId
                        try {
                          await addFavorite(
                            ovenId,
                            myName,
                            myName !== ovenId,
                            true,
                          )

                          await addFavorite(
                            ovenId,
                            ovenId,
                            myFavoriteFavorited || false,
                            false,
                          )
                        } catch (e) {
                          console.error(e)
                        }

                        await queryClient.refetchQueries(['favorites', ovenId])
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          )}
          {publishOpen && (
            <Modal onCloseModal={() => setPublishOpen(false)}>
              <ModalHeader onClickClose={() => setPublishOpen(false)}>
                <h1 className="text-xl font-bold">Publish OTA</h1>
              </ModalHeader>
              <ImagePublish defaultDevices={[ovenId]} />
            </Modal>
          )}
        </div>
      </div>

      <div className="flex flex-row justify-around flex-wrap gap-4">
        <div className="flex flex-col space-y-4">
          <OvenDataViewer
            error={isError ? 'Error loading oven' : undefined}
            loading={isLoading}
            oven={data}
          />
        </div>
        <div className="flex flex-col space-y-4">
          <OvenCommandsExecutor ovenId={ovenId} />
        </div>
        <div className="flex flex-col space-y-4 min-w-96">
          <OvenEventViewer ovenId={ovenId} />
        </div>
        <div className="flex flex-col space-y-4 min-w-96">
          <ThingJobHistory ovenId={ovenId} />
        </div>
        <div className="flex-col space-y-4 min-w-96 w-full">
          <OvenLogs ovenId={ovenId} />
        </div>
        <div className="flex-col space-y-4 min-w-96 w-full">
          <OvenEvents ovenId={ovenId} />
        </div>
      </div>
      {data && editOpen && (
        <EditThingsModal
          invalidate={() => {
            queryClient.refetchQueries(['oven', ovenId])
          }}
          selected={[data]}
          setEditOpen={setEditOpen}
        />
      )}
    </div>
  )
}

export default PreOvenPage
